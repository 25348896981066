.container {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-width: 500px;
    width: 100%;
    margin: auto;
    z-index: 999;
}

.container > form {
    width: 70%;
    border: 3px solid #e7dc00;
    padding: 30px;
    border-radius: 16px;
    box-shadow: 0 0 13px 2px #EDFF01;
    background: rgba(0, 0, 0, 0.3);
    z-index: 999;
}

.error {
    margin: 9px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.9);
    border: 1px;
    border-radius: 9px;
    font-size: 19px!important;
    border-color: #EDFF01;
}

.field {
    display: flex;
    flex-direction: column;
}

h1 {
    margin-block-start: -19px;
    color: white;
    font-size: 46px;
}

input {
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 9px;
    padding-right: 9px;
    font-size: 23px;
    height: 30px;
    color: white;
    border-radius: 9px;
    border-color: #EDFF01;
    box-shadow: 0 0 5px 2px #EDFF01;
    background-color: rgba(0, 0, 0, .6);
}

input::placeholder {
    color: #eeff0185;
    font-size: 19px;
}

label {
    padding-bottom: 10px;
    padding-left: 3px;
    color: white;
    font-size: 33px;
}

pre {
    width: 70%;
    color: #fff;
    font-size: larger;
}

.signup-button {
    background-color: rgba(0, 0, 0, .3) !important;
    padding: 5px;
    border-radius: 10px;
    border: 2px solid #e7dc00;
    box-shadow: 0 0 9px 2px #EDFF01;
    width: 100%;
    color: white;
    font-size: 23px;
    margin-top: 16px;
}

.signup-button:hover {
    background-color: #fffb00 !important;
    cursor: pointer;
    color: black;
}

p {
    color: rgb(255, 0, 0);
}

.create-account {
    margin-top: 13px;
    padding: 5px;
    color: #dfdfdf;
    z-index: 999;
    font-size: 23px;
}

.success {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    margin-bottom: 90px;
}
.success-text {
    color: #EDFF01;
    font-size: 39px;
    border: 1px solid #EDFF01;
    border-radius: 300px;
    box-shadow: 0 0 15px 3px #EDFF01;
    background-color: rgba(0, 0, 0, .9);
    padding: 23px;
    padding-left: 29px;
    padding-right: 29px;
    padding-bottom: 33px;
}

span{
    color: rgb(217, 255, 0);
    cursor: pointer;
}