.App {
  text-align: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('../assets/galaxy.png'); /* Apply background image to the body */
  background-color: #060605;
  background-repeat: no-repeat;
  background-position: center center; /* Center the background image */
  background-attachment: fixed; /* Ensure the background image stays fixed while scrolling */
  background-size: cover; /* Cover the entire viewport with the background image */
  height: 100vh; /* Set the height of the body to the full viewport height */
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
}
body::before {
  content: ""; /* Necessary for pseudo-elements */
  /* Position the pseudo-element to cover the entire body */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* Set the background color to create a darkening effect */
  background-color: rgba(0, 0, 0, 0.26); /* Adjust the alpha (last value) to control darkness */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* ------------------------------------------- Cards ------------------------------------------- */
.top-card-row {
  display: flex;
  justify-content: center;
  z-index: 3;
  margin-top: 23px;
  margin-bottom: 33px;
}
.bottom-card-row {
  display: flex;
  justify-content: center;
  z-index: 3;
}
.card {
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 2px solid #EDFF01;
  box-shadow: 0 0 16px 2px #EDFF01;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.396);
  width: 283px;
  height: 360px;
  margin-left: 13px;
  margin-right: 13px;
}
.card h3 {
  color: white;
  margin-top: 6px;
  margin-bottom: 6px;
}
.card p {
  color: white;
}
.poke-card {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  border: 2px solid #EDFF01;
  box-shadow: 0 0 16px 2px #EDFF01;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.396);
  width: 283px;
  height: 383px;
  margin-left: 13px;
  margin-right: 13px;
}
.poke-card h3 {
  color: white;
  margin-top: 6px;
  margin-bottom: 6px;
}
.poke-card p {
  color: white;
}
.sprite-div {
  position: relative;
}
.sprite {
  height: 93px;
  width: auto;
  margin-top: 6px;
  margin-bottom: 6px;
}
.stunfisk {
  width: 199px!important;
  height: auto!important;
  margin-top: 38px!important;
  margin-bottom: 38px!important;
}
.shiny-icon {
  position: absolute;
  top: 0;
  right: 23px;
  width: 39px!important;
  height: 38px!important;
}
.card-typing {
  display: flex;
  justify-content: center;
  margin-top: 9px;
}
.type {
  width: 86px;
  height: auto;
  margin-right: 3px;
  margin-left: 3px;
}
.card-wh {
  display: flex;
  justify-content: space-around;
  font-size: 24px;
}
.weight {
  margin-top: 3px;
  margin-bottom: 3px;
}
.height {
  margin-top: 3px;
  margin-bottom: 3px;
}
.card-text {
  margin-top: 0px;
  margin-bottom: 16px;
  padding: 0px;
}
.flavor-text {
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 13px;
  padding-right: 13px;
}
.card-moves {
  margin-top: 6px;
  margin-bottom: 6px;
  font-size: 16px;
}
.card-level {
  margin-top: 0px!important;
  margin-bottom: 0px!important;
  font-size: 23px;
}
.card-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 16px;
  margin-bottom: 16px;
  font-size: 19px;
}
.card-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 16px;
  margin-bottom: 16px;
  font-size: 19px;
  position: absolute;
  bottom: -6px;
  left: 9px;
  width: calc(100% - 23px);
}
.cart-icon {
  width: 36px;
  height: 36px;
  margin-right: 19px;
}
.cart-icon:hover {
  cursor: pointer;
  width: 37px;
  height: 37px;
}
.poke_shiny_icon {
  width: 36px!important;
  height: 36px!important;
  margin-right: 19px!important;
}
.poke_shiny_icon:hover {
  cursor: pointer;
  width: 37px!important;
  height: 37px!important;
}
.failed {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 79vh;
  text-align: center;
}
.failed-text {
  color: #EDFF01;
  font-size: 39px;
  border: 1px solid #EDFF01;
  border-radius: 300px;
  box-shadow: 0 0 15px 3px #EDFF01;
  background-color: rgba(0, 0, 0, .9);
  padding: 23px;
  padding-left: 29px;
  padding-right: 29px;
  padding-bottom: 33px;
}
/* -------------------------------------------------------------------------------------------- */


/* ------------------------------------------- Filter ------------------------------------------- */
/* ------------------ Filter button styling ---------------- */
.filter-button {
  background-color: rgba(0, 0, 0, 0.0) !important;
  width: 79px;
  height: 54px;
  opacity: 1;
  border-radius: 9px;
  border-color: #EDFF01;
  box-shadow: 0 0 10px 2px #EDFF01;
  position: fixed;
  top: 11.9%;
  left: 3.9%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.1s; /* Add transition for smooth effect */
  z-index: 34;
}
.filter-button:hover {
  cursor: pointer;
  box-shadow: 0 0 15px 3px #EDFF01;
}
/* Hide button when form is visible */
.hide.filter-button {
  opacity: 0;
}
.filter-icon {
  width: 45px;
  height: 36px;
}
/* ------------------------------------------------------ */


/* ---------------------- Filter form styling ------------------- */
.filter-form {
  position: fixed;
  top: 11.3%;
  left: 3.9%;
  padding: 20px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 9px;
  border: 2px solid #EDFF01;
  box-shadow: 0 0 15px 3px #EDFF01;
  background-color: rgba(0, 0, 0, .86);
  height: 0;
  width: 0;
  opacity: 0;
  overflow: hidden;
  transition: height 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  z-index: 33;
}
.poke-filter-form {
  position: fixed;
  top: 11.3%;
  left: 3.9%;
  padding: 20px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 9px;
  border: 2px solid #EDFF01;
  box-shadow: 0 0 15px 3px #EDFF01;
  background-color: rgba(0, 0, 0, .86);
  height: 0;
  width: 0;
  opacity: 0;
  overflow: hidden;
  transition: height 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  z-index: 33;
}
.display.filter-form { /* Display form when button is hidden */
  height: 796px;
  width: 430px;
  opacity: 1;
}
.display.poke-filter-form { /* Display form when button is hidden */
  height: 730px;
  width: 430px;
  opacity: 1;
}


/* ------------- Form entries ------------- */
.form-entry {
  display: flex;
  flex-direction: row; /* Display label and input side by side */
  align-items: center; /* Align items vertically */
  margin-bottom: 16px; /* Adjust margin as needed */
}
.two-line-entry {
  display: flex;
  flex-direction: row; /* Display side by side */
  justify-content: space-between; /* Separate the two entries */
}
.typing-entry {
  display: flex;
  margin-bottom: 13px;
}
.two-line-entry-cost {
  display: flex;
  flex-direction: row; /* Display side by side */
}
.id-entry {
  margin-right: 30px;
}
/* ---------------------------------------- */


/* ------------- Form labels ------------- */
.form-label {
  color: #EDFF01;
  font-size: 23px;
}
.name-label {
  margin-right: 27px;
}
.id-label {
  margin-right: 66px;
}
.shiny-label {
  margin-right: 30px;
}
.type-label {
  margin-right: 39px;
}
.move-label {
  margin-right: 23px;
}
.ability-label {
  margin-right: 25px;
}
.region-label {
  margin-right: 19.5px;
}
.generation-label {
  margin-right: 23px;
}
.max-label {
  margin-left: 13px;
  margin-right: 13px;
  text-wrap: nowrap;
}
.match-label {
  text-wrap: nowrap;
}
.cost-label {
  margin-right: 46px;
}
.match-label {
  margin-right: 23px;
}
/* ---------------------------------------- */


/* ------------- Form inputs ------------- */
.form-input {
  flex: 1; /* Take up remaining space */
  font-size: 23px;
  height: 30px;
  padding-left: 16px;
  padding-bottom: 9px;
  color: white;
  border-radius: 13px;
  border-color: #EDFF01;
  box-shadow: 0 0 5px 2px #EDFF01;
  background-color: rgba(0, 0, 0, 1);
}
.form-input::placeholder {
  color: #eeff015f;
  font-size: 19px;
}
.form-input:hover {
  box-shadow: 0 0 16px 2px #EDFF01;
}
.name-input {
  width: 103px;
}
.id-input {
  width: 66px;
  padding-left: 19px;
}
select {
  height: 43px!important;
  font-size: 23px!important;
  padding-bottom: 0px!important;
  -webkit-appearance: none;
  appearance: none;
}
.arrow-icon {
  width: 23px;
  height: auto;
  position: fixed;
  left: 32%;
  pointer-events: none;
}
.shiny-input {
  padding-right: 13px!important;
  width: 103px;
}
.shiny-input options {
  font-size: 23px!important;
  padding-right: 16px;
}
/* ------ Typing section ------ */
.type-row {
  display: flex;
  align-items: center;
  margin-top: 6px;
}
.type-image {
  width: 80px;
  height: auto;
  cursor: pointer;
  margin-bottom: 6px;
  margin-right: 9px;
}
.type-selected {
  box-shadow: 0 0 6px 3px #EDFF01;
  border-radius: 19px;
}
/* ---------------------------- */
.cost-input {
  width: 107px;
}
.cost-input::placeholder {
  text-align: start;
  padding-left: 31px;
}
/* ---------------------------------------- */


/* ------------- Form buttons ------------- */
.close-icon {
  width: 30px;
  margin-left: 33px;
  height: auto;
  position: relative;
}
.close-icon:hover {
  cursor: pointer;
  width: 33px;
  height: auto;
  margin-left: 30px;
}
.form-buttons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0;
  margin-top: 23px;
  /* margin-bottom: 23px; */
}
.form-buttons button {
  width: 190px;
  height: 60px;
  border-radius: 19px;
  border-color: #EDFF01;
  box-shadow: 0 0 9px 2px #EDFF01;
  font-size: 40px;
}
.form-clear {
  background-color: black;
  color: white;
}
.form-clear:hover {
  cursor: pointer;
  box-shadow: 0 0 16px 2px #EDFF01;
}
.form-submit {
  background-color: #EDFF01;
  color: black;
}
.form-submit:hover {
  cursor: pointer;
  box-shadow: 0 0 16px 2px #EDFF01;
}
/* ---------------------------------------- */
/* ------------------------------------------------------------ */
/* ---------------------------------------------------------------------------------------- */


.g {
  position: fixed;
  z-index: 999;
  bottom: 26px;
  right: 20px;
  height: 163px;
}

header {
  display: flex;
  align-items: start;
  justify-content: end;
  font-size: calc(10px + 2vmin);
  color: #EDFF01;
}

/*------------- Navbar hover animation ------------*/
.hover-underline-animation {
  display: inline-block;
  position: relative;
}
.hover-underline-animation:hover {
  color: #ccdb00!important;
}
.hover-underline-animation::after {
content: '';
position: absolute;
width: 96%;
transform: scaleX(0);
height: 2px;
bottom: -3px;
left: 1px;
background-color: #ccdb00;
transform-origin: bottom right;
transition: transform 0.25s ease-out;
}
.hover-underline-animation:hover::after {
transform: scaleX(1);
transform-origin: bottom left;
}
/*-----------------------------------------------*/

.logo {
  position: fixed;
  z-index: 999;
  top: 26px;
  left: 30px;
  height: 60px;
}

.nav-links span {
  text-decoration: none;
  color: inherit;
}

.nav-links {
  display: flex;
  margin-top: 40px;
  list-style-type: none;
}

.nav-links li {
  margin-right: 46px;
  cursor: pointer;
}


/* ---------------- Pagination ---------------- */
.page-index {
  color: #EDFF01;
  font-size: 66px;
  font-weight: 600;
  position: fixed;
  top: -4.6%;
  left: 49%;
}
.pagination-buttons button {
  background-color: rgba(0, 0, 0, 0.3);
  border: 2px solid #EDFF01;
  box-shadow: 0 0 6px 2px #EDFF01;
  color: #EDFF01;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 23px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
}
.pagination-buttons button:hover {
  box-shadow: 0 0 13px 3px #EDFF01;
}
.prev {
  position: fixed;
  top: 48.13%;
  left: 1%;
}
.next {
  position: fixed;
  top: 48.13%;
  right: 2%;
}
.prevm {
  position: fixed;
  top: 50.3%;
  left: 1.3%;
}
.nextm {
  position: fixed;
  top: 50.3%;
  right: 2.3%;
}
.prevx {
  position: fixed;
  top: 57.1%;
  left: 2.36%;
}
.nextx {
  position: fixed;
  top: 57.1%;
  right: 2.19%;
}
/* ----------------------------------------- */


/* ------------ Loading spinner ------------ */
.ring
{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:150px;
  height:150px;
  background: rgba(0, 0, 0, 0.43);
  border:3px solid #3c3c3c;
  border-radius:50%;
  text-align:center;
  line-height:150px;
  font-family:sans-serif;
  font-size:20px;
  color:#fff000;
  letter-spacing:4px;
  text-transform:uppercase;
  text-shadow:0 0 10px #fff000;
  box-shadow:0 0 20px rgba(0,0,0,.8);
}
.ring:before
{
  content:'';
  position:absolute;
  top:-3px;
  left:-3px;
  width:100%;
  height:100%;
  border:3px solid transparent;
  border-top:3px solid #fff000;
  border-right:3px solid #fff000;
  border-radius:50%;
  animation:animateC 2s linear infinite;
}
.ring-span
{
  display:block;
  position:absolute;
  top:calc(50% - 2px);
  left:50%;
  width:50%;
  height:4px;
  background:transparent;
  transform-origin:left;
  animation:animate 2s linear infinite;
}
.ring-span:before
{
  content:'';
  position:absolute;
  width:16px;
  height:16px;
  border-radius:50%;
  background:#fff000;
  top:-6px;
  right:-8px;
  box-shadow:0 0 20px #fff000;
}
@keyframes animateC
{
  0%
  {
    transform:rotate(0deg);
  }
  100%
  {
    transform:rotate(360deg);
  }
}
@keyframes animate
{
  0%
  {
    transform:rotate(45deg);
  }
  100%
  {
    transform:rotate(405deg);
  }
}
/* ------------------------------------------------- */

#root {
  margin: 0!important;
  height: 100vh;
  width: 100vw;
}
