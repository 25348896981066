/* About.css */
.about-section {
  text-align: center;
  color: white;
}

.about-creator-cards {
  display: flex;
  justify-content: center;
  gap: 50px; /* Space between cards */
}

.about-creator-card {
  width: 630px;
  height: 760px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white; /* Ensures all text is white */
  border: 2px solid #EDFF01;
  box-shadow: 0 0 20px rgba(255, 255, 0, 0.75);
  border-radius: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Arial', sans-serif;
  position: relative; /* Needed for absolute positioning of the role */
}

.about-creator-image {
  width: 60%;
  height: 360px;
  max-height: 280px;
  object-fit: cover;
  object-position: center 10%; /* Adjusted object position */
  border-radius: 8px;
  margin-bottom: 0px;
}

.about-creator-image-first {
  width: 60%;
  height: 360px;
  max-height: 280px;
  object-fit: cover;
  object-position: center 30%; /* Adjusted object position for the first card */
  border-radius: 8px;
  margin-bottom: 0px;
}

.about-card-title {
  font-size: 24px;
  margin-bottom: 13px;
  color: white; /* Explicitly set all text to white */
}

.about-weight, .about-height {
  color: white;
  font-size: 30px;
  padding-left: 103px;
  padding-right: 103px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.about-card-moves {
  font-size: 23px!important;
  color: white;
  margin-top: 3px;
  margin-bottom: 9px;
  padding-left: 13px;
  padding-right: 13px;
}

.about-card-level {
  font-size: 30px;
  margin-top: 6px;
  margin-bottom: 0px;
}

.about-card-title {
  margin-top: 13px;
}

.about-description {
  font-size: 23px; /* Decrease font size for better alignment */
  margin-top: 10px; /* Add margin to raise it up */
  color: white;
  padding-left: 16px;
  padding-right: 16px;
}

.about-card-typing {
  display: flex;
  justify-content: center;
  gap: 39px; /* Space between types */
}

.about-type-icon {
  width: 160px; /* Adjusted size of type icons */
  height: auto;
  margin-right: 5px;
  margin-top: 5%;
}

.about-card-wh {
  display: flex;
  justify-content: space-between;
  padding-left: 23px;
  padding-right: 23px;
  width: 100%;
  font-size: 18px; /* Slightly larger for visibility */
}

.about-role {
  position: absolute;
  bottom: 5px; /* Closer to the bottom */
  left: 30px; /* Closer to the left */
  font-size: 20px; /* Adjusted font size */
}

.about-shiny-icon {
  position: absolute;
  bottom: 16px;
  right: 23px;
  width: 46px; /* Adjust size as necessary */
  height: 46px; /* Adjust size as necessary */
}

.sprite-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.8);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

@media (max-width: 768px) {
  .about-creator-cards {
    flex-direction: column;
  }

  .about-creator-card {
    width: 80%;
    height: auto;
  }

  .about-creator-image {
    max-height: 180px;
  }

  .about-type-icon {
    width: 80px; /* Adjusted for mobile view */
  }
}
